// import * as firebase from "firebase/app";
import * as firebase from "firebase/app";

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyCR5ghNH38nhP-CZdAriH2M234As9fReKk",
  authDomain: "mectop-database.firebaseapp.com",
  projectId: "mectop-database",
  storageBucket: "mectop-database.appspot.com",
  messagingSenderId: "1072858939477",
  appId: "1:1072858939477:web:65fe9f814860a03b4d94dc",
};
// firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
export default firebase;
