import firebase from "./firebase";

import { getFirestore, collection, doc, query, where, setDoc, addDoc, getDoc, getDocs, onSnapshot, serverTimestamp, runTransaction, arrayUnion, arrayRemove, orderBy, limit } from "@firebase/firestore";
import { deleteField, deleteDoc } from "@firebase/firestore";
import Auth from "./auth";
import { print } from "../plugins/base";

const db = getFirestore();

const fields_approved = ["name", "phone", "address", "phone", "owner", "note", "status", "items", "employee_note", "employee", "payment", "ship_fee"];

var debug = async (...args) => {
  window.HWDEBUG && console.log(...args)
}

function convertData(newData, oldData = {}, fields = []) {
  debug(newData)
  debug(oldData)
  const uid = Auth.auth.currentUser.uid;
  const current_time = new Date().getTime();
  var data_convert = {};
  Object.entries(newData).forEach(([_key, _val]) => {
    if (fields.indexOf(_key) != -1) {
      if (oldData.hasOwnProperty(_key) && _val == null) {
        data_convert[_key] = deleteField()
      } else if ( (oldData.hasOwnProperty(_key) && oldData[_key] != _val) || (!oldData.hasOwnProperty(_key) && _val != null)) {
        data_convert[_key] = _val;
      }
    }
  });
  if (Object.keys(data_convert).length > 0) {
    var data_compile = Object.assign(
      {},
      data_convert,
      {
        worker: uid,
        last_updated: current_time,
        ...(Object.keys(oldData).length == 0 && {created_time: newData.hasOwnProperty('created_time') ? newData['created_time'] : current_time})
      }
    )
    debug(data_compile)
    return data_compile;
  } else {
    return false;
  }
}

class Build_Firestore {
  constructor() {
    this.onSnapshot_sub = {};
  }
  append = async (target, data, fields) => {
    // console.log(target);
    var tar;
    var data_convert;
    if (target.length % 2 == 1) {
      tar = collection(db, ...target);
      data_convert = convertData(data, {}, fields);
      var res =  await addDoc(tar, data_convert);
      return res
    } else {
      var doc_id = target.pop();
      if (doc_id.endsWith("-force-delete")) {
        target.push(doc_id.slice(0, -"-force-delete".length))
      } else {
        target.push(doc_id)
      }
      tar = doc(db, ...target);
      var trans = await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(tar);
        if (!sfDoc.exists()) {
          data_convert = convertData(data, {}, fields);
          debug(data_convert)
          if (data_convert) {
            var res = await transaction.set(tar, data_convert);
            return res
          } else {
            return "No have change"
          }
        } else {
          if ( doc_id.endsWith("-force-delete")) {
            debug(`Delete: ${doc_id}`)
            var res = await transaction.delete(tar)
            return res
          } else {
            const oldDoc = sfDoc.data();
            data_convert = convertData(data, oldDoc, fields);
            debug(data_convert);
            if (data_convert) {
              var res = await transaction.update(tar, data_convert);
              return res
            } else {
              return "No have change";
            }
          }
        }
      });
      return trans
    }
  };

  getOrders = async (projectId) => {
    // print(projectId);
    const docRef = collection(db, ...["projects", projectId, "orders"]);
    const docSnap = await getDocs(docRef);
    return docSnap;
  };

  getData = async (target, callback) => {
    if (Array.isArray(target)) {
      if (target.length % 2 == 1) {
        const docRef = collection(db, ...target);
        const res = await getDocs(docRef);
        callback(res);
      } else {
        const docRef = doc(db, ...target);
        const res = await getDoc(docRef);
        callback(res);
      }
    } else {
      if (target.hasOwnProperty("query")) {
        var queries = [];
        // print(target);
        Object.entries(target).forEach(([_key, _val]) => {
          switch (_key) {
            case "query":
              queries.unshift(collection(db, ..._val));
              break;
            case "where":
              // print(_val);
              _val.forEach((_where) => queries.push(where(..._where)));
              break;
            case "limit":
              queries.push(limit(_val));
              break;
            case "orderby":
              queries.push(orderBy(..._val));
          }
        });
        const q = query(...queries);
        const res = await getDocs(q);
        callback(res);
      } else {
        print("Not found: query");
      }
    }
  };

  onSnapshot = async (target, assign, callback) => {
    //   Remove sub previous
    if (this.onSnapshot_sub[assign]) {
      this.onSnapshot_sub[assign]();
    }

    if (!Array.isArray(target)) {
      if (target.hasOwnProperty("query")) {
        var queries = [];
        // print(target);
        Object.entries(target).forEach(([_key, _val]) => {
          switch (_key) {
            case "query":
              queries.unshift(collection(db, ..._val));
              break;
            case "where":
              _val.forEach((_where) => queries.push(where(..._where)));
              break;
            case "limit":
              queries.push(limit(_val));
              break;
            case "orderby":
              queries.push(orderBy(..._val));
          }
        });
        this.onSnapshot_sub[assign] = onSnapshot(
          query(...queries),
          (records) => callback(records.docs.map((_doc) => [_doc.id, _doc.data()])),
          (error) => console.error(error)
        );
        return this.onSnapshot_sub[assign];
      } else {
        print("Not found: query");
      }
    }
  };
}

const Firestore = new Build_Firestore();
export { arrayUnion, arrayRemove };
export default Firestore;
