import React from "react";
import ReactDOM from "react-dom";

import Auth from "../modules/firebase/auth";
import Firestore from "../modules/firebase/firestore";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields_approved_upload: ["name", "uid", "avatar", "email"],
      database: {
        projects: ["projects"],
      },
      user: false,
    };
  }

  componentDidMount() {
    Auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: user.auth.currentUser });
      } else {
        this.setState({ user: false });
      }
    });
  }

  request_project = () => {
    const projectId = document.querySelector('input[name="request_project"]').value;
    const user = this.state.user;
    Promise.resolve(
      Firestore.append(
        ["projects", projectId, "employees", user.uid],
        {
          uid: user.uid,
          name: user.displayName,
          avatar: user.photoURL,
          email: user.email,
        },
        this.state.fields_approved_upload
      )
    ).then((data) => {
      if (data) {
        this.setState({ request_review: true });
      }
    });
  };
  render() {
    return (
      <div>
        {!this.state.user ? (
          <div className='card card-body'>You need login!</div>
        ) : (
          <div className='card'>
            <div className='card-header'>
              <h2>All Projects</h2>
            </div>
            <div className='card-body'>
              {!this.state.projects_admin && !this.state.projects_editor && (
                <div className='card-body'>
                  <span>Nothing is here!</span>
                </div>
              )}
              {["admin", "editor"].map(
                (_sec) =>
                  this.state[`projects_${_sec}`] && (
                    <div className='card'>
                      <div className='card-header' style={{ textTransform: "capitalize" }}>
                        {_sec}
                      </div>
                      <div className='card-body'>
                        {this.state[`projects_${_sec}`].map(([_projectkey, _projectval]) => (
                          <div className='card' style={{ cursor: "pointer" }} onClick={() => (window.location = `/project.html?project=${_projectkey}`)}>
                            <div className='card-header'>{_projectval.name}</div>
                            <div className='card-body'>{_projectkey}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className='card card-body'>
              <div className='row'>
                <div className='col-4'>
                  <input name='request_project' class='form-control' type='text' placeholder='Insert projectId' onChange={(event) => this.setState({ request_review: false })}></input>
                </div>
                <div className='col-3'>
                  {this.state.request_review ? (
                    <button className='btn btn-sucess'>Request Success</button>
                  ) : (
                    <button className='btn btn-primary' onClick={this.request_project}>
                      Request Review
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidUpdate() {
    if (!this.state.projects_editor && this.state.user) {
      Firestore.onSnapshot(
        {
          query: this.state.database.projects,
          where: [["editor", "array-contains", this.state.user.uid]],
        },
        "getprojects_editor",
        (records) => {
          this.setState({
            projects_editor: records,
          });
        }
      );
    }
    if (!this.state.projects_admin && this.state.user) {
      Firestore.onSnapshot(
        {
          query: this.state.database.projects,
          where: [["admin", "array-contains", this.state.user.uid]],
        },
        "getprojects_admin",
        (records) => {
          this.setState({
            projects_admin: records,
          });
        }
      );
    }
  }
}

if (typeof window !== "undefined") {
  ReactDOM.hydrate(<Index />, document.getElementById("wrapper-content"));
}

export default Index;
