// import * as firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import firebase from "./firebase.jsx";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, getIdToken, signOut } from "firebase/auth";
import { print } from "../plugins/base.jsx";

// Initialize Firebase
// var firebaseConfig = {
//   apiKey: "AIzaSyCR5ghNH38nhP-CZdAriH2M234As9fReKk",
//   authDomain: "mectop-database.firebaseapp.com",
//   projectId: "mectop-database",
//   storageBucket: "mectop-database.appspot.com",
//   messagingSenderId: "1072858939477",
//   appId: "1:1072858939477:web:65fe9f814860a03b4d94dc",
// };
// // firebase.initializeApp(firebaseConfig);
// const firebase = initializeApp(firebaseConfig);

const auth = getAuth();
auth.languageCode = "vn";
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

const provider = new GoogleAuthProvider();
// Request Auth2.0
provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

class Build_Auth {
  constructor(auth, provider) {
    this.auth = auth;
    this.provider = provider;
    this.user;
  }

  signInGoogleWithPopup = (url) => {
    signInWithPopup(this.auth, this.provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        this.user = result.user.auth.currentUser;
        print(result.user);
        // ...
        window.location = url;
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  signOut = () => {
    signOut(this.auth)
      .then(() => {
        window.location = "/";
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  onAuthStateChanged = (callback) => {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.user = user.auth.currentUser;
      } else {
        this.user = false;
      }
      callback(user);
    });
  };
}

const Auth = new Build_Auth(auth, provider);

export default Auth;
