function print(t) {
  console.log(t);
}

class Build_Base {
  constructor() {
    this.phone = new RegExp("(\\+84|0){1}(9|8|7|5|3){1}[0-9]{8}");
  }
  getParam(p) {
    var match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
  vnd = (number) => {
    if (typeof number == 'number') {
      return new Intl.NumberFormat("vi-vn", {
        style: "currency",
        currency: "VND",
      }).format(Number(number));
    } else {
      return ""
    }
  };
  items = (item_list, products) => {
    if (!Array.isArray(item_list)) {
      try {
        if (!Array.isArray(JSON.parse(item_list))) {
          item_list = JSON.parse(item_list);
        }
      } catch (err) {
        return `Items is not array: ${String(item_list)}`;
      }
    }
    var new_items = []
    item_list.forEach(item => {
      if (item.hasOwnProperty('id') && products.hasOwnProperty(item.id)) {
        new_items.push({ id: item.id,
        name: item.name ? item.name : products[item.id].name,
        sale_price: item.sale_price ? item.sale_price : products[item.id].sale_price,
        quantity: item.quantity ? item.quantity : 1
      })
      } else {
        return `Item is not have 'ID': ${String(item)}`
      }
    });
    return new_items
  };
  timeClient = (time) => {
    var d_time = new Date(time);
  return `${d_time.getDate()}/${d_time.getMonth() +1}/${d_time.getFullYear()} ${d_time.getHours()}:${d_time.getMinutes()}:${d_time.getSeconds()}`
  };
}

class Build_Cookies {
  set(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/";
  }

  get() {
    let ca = document.cookie.split(";");
    return Object.fromEntries(
      Array.from(arguments).map((arg) => {
        let name = arg + "=";
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return [arg, c.substring(name.length, c.length)];
          }
        }
        return [arg, null];
      })
    );
  }
}

class Build_Table {
  constructor(node) {
    this.node = node;
  }
  update() {
    this.table_node = document.querySelector(node);
    this.tlist = Array.from(this.table_node.rows).map((tr) => Array.from(tr.cells).map((cell) => cell.textContent));
    this.thead = this.tlist[0];
    this.tbody = this.tlist.slice(1);
  }
}

class Build_Alert {
  success(context) {
    return (
      <div class='alert alert-success d-flex align-items-center' role='alert'>
        <i class='bi bi-cloud-check-fill'></i>
        <div>{context}</div>
      </div>
    );
  }

  danger(context) {
    return (
      <div class='alert alert-danger d-flex align-items-center' role='alert'>
        <svg class='bi flex-shrink-0 me-2' width='24' height='24' role='img' aria-label='Danger:'>
          <use xlinkHref='#exclamation-triangle-fill' />
        </svg>
        <div>{context}</div>
      </div>
    );
  }
}

class Build_Badge {
  danger(context) {
    return (
      <span class='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
        {context}
        <span class='visually-hidden'>unread messages</span>
      </span>
    );
  }
}

const Base = new Build_Base();

export { print, Build_Base, Build_Cookies, Base };
